import React, { useState } from 'react'

// Components
import Button from '../Elements/Button'
import InputField from '../Elements/InputField'
import RadioButton from '../Elements/RadioButton'
import Switch from '../Elements/Switch'
import Tag from '../Elements/Tag'
import Tooltip from '../Elements/Tooltip'
import Logo from '../../assets/images/picture.png'

// API
import { toolService } from '../../api'


function CreateTool(props) {
    const [errors, setErrors] = useState({})
    const [category, setCategory] = useState('discover')

    const [tags, setTags] = useState([])
    const [tagInputValue, setTagInputValue] = useState("")

    const [toolImage, setToolImage] = useState(Logo)

    const [toolCreated, setToolCreated] = useState(false)

    var is_admin = props.currentUser.role === 'SUPER_ADMIN' ? true : false


    if(Object.keys(tags).length === 0 && props.tags && Object.keys(props.tags).length > 0){
        setTags(props.tags.reduce((a, v) => ({ ...a, [v]: v}), {}) )
    }

    function handleSubmit(e){
        e.preventDefault()

        var form_element = document.getElementById('create-tool')
        var form_data = new FormData(form_element)

        var body = {}
        let tags_ = false
        form_data.forEach((value, key) => {
            if(value) {
                if (key === 'tags'){
                    if (!('tags' in body) || !tags_){
                        body['tags'] = []
                    }
                    body['tags'].push(value)
                    form_data.delete(key)
                    tags_ = true
                }
                else{
                    body[key] = value
                }
            }
        })
        let tagsSelected = JSON.stringify(tags);
        form_data.append("tags", [tagsSelected])


        toolService.create(form_data)
        .then((response) => {

            is_admin && props.onClose()
            props.reset()
            setToolCreated(true)

        })
        .catch(error => {
            console.log("Error creating new tool", error)
            if(Array.isArray(error)){
                error = {non_field_errors: error[0]}
            }
            setErrors({...error})
        })
    }

    const handleCloseModal = () => {
        props.onClose()
        setToolCreated(false)
    }

    function handleFileLoader() {
        var element = document.getElementById("file-loader")
        element.click()
    }

    function handleChangeImage({ target }) {
		if (target.files && target.files[0]) {
            var ext =  target.files[0].name.split('.').pop().toLowerCase();
			const allowedExtensions = ["png", "jpeg", "gif", "jpg"];
			if(allowedExtensions.includes(ext)){
			setToolImage(URL.createObjectURL(target.files[0]))
            }
		}
	}

    function handleClickCategory(e){
        setCategory(e.target.value)
    }

    function handleChangeTagInput(e){
        setTagInputValue(e.value)
    }

    function handleTagKeyDown(e){
        if(e.key === 'Enter'){
            if (e.target.value && e.target.value.replace(/\s/g, '').length) {
                let new_tags = [...tags, e.target.value];
                setTags(new_tags);
            } else {
                e.target.value = null; // reset the input
            }
        }
    }

    function handleDeleteTag(tag){
        let new_tags = tags.filter(t => t !== tag);
        setTags(new_tags);
    }

    let tag_elements = []
    for (const tag of Object.values(tags)){
        tag_elements.push(
        <React.Fragment>
            <Tag tag={tag} delete={true} onDelete={handleDeleteTag}/>
            <input type="hidden" name="tags" value={tag}/>
        </React.Fragment>)
    }

    return(  
        <>
        { (toolCreated && !is_admin) ? 
        <div className='center'>
            <h1 className="title is-dark mb-30">Tool Successfully Created</h1>
            <p className='text'>The tool you just submitted is now under review. As soon as it is approved by an admin, it will appear in the general list of tools.</p> 
            <Button text="OK" class="mt-30 one-third-width blue-btn subheading-light" type='button' onClick={handleCloseModal}/>
        </div>
        :
        <form id="create-tool" onSubmit={handleSubmit}>
            <h1 className="title is-dark mb-30">Add Tool</h1>
            <InputField name="name"  label='Tool title' type="text" placeholder={'Enter a name...'} required={true} error={errors.name}/>
            <div className="flexer flexer-vcenter my-30" onClick={handleFileLoader}>
                <div className='flexer-2 detail__picturec_content side-margin-0'>

                <img className="tool-detail__picture" src={toolImage} alt="tool" />
                </div>
                <input id="file-loader" name="tool_pic" type="file" accept='.png, .jpeg, .jpg, .gif' className="file-input" onChange={handleChangeImage}/>
                <p className="button blue-btn mx-20">Upload Logo</p>
            </div>
            {is_admin ? 
                    (<div className="my-30">
                        <Switch label="Approved" name="approved"/>
                    </div>) 
            : null}
            <p className="text is-bold is-dark">Select tool category</p>
            <div className='flexer flexer-wrapper'>
                <RadioButton label="Discover" class="flexer-4 mb-15" name="categories" value="Discover" checked={category ==='Discover'? true: false} required={true} onClick={handleClickCategory}/>
                <RadioButton label="Develop" class="flexer-4 mb-15" name="categories" value="Develop" checked={category ==='Develop'? true: false} required={true} onClick={handleClickCategory}/>
                <RadioButton label="Monitor" class="flexer-4 mb-15" name="categories" value="Monitor" checked={category ==='Monitor'? true: false} required={true} onClick={handleClickCategory}/>
                <RadioButton label="Resources" class="flexer-4 mb-15" name="categories" value="Resources" checked={category ==='Resources'? true: false} required={true} onClick={handleClickCategory}/>
                <RadioButton label="Training & Support" class="flexer-4 mb-15" name="categories" value="Training & Support" checked={category ==='Training & Support'? true: false} required={true} onClick={handleClickCategory}/>
            </div>

            <p className="heading is-dark">Add tags for your tool</p>
            <label className="input-field__label">Add a tag and then press ENTER. You are able to add several tags.</label>
            <InputField 
                placeholder="Enter a tag name" 
                value={tagInputValue} 
                error={errors.tags} 
                onKeyDown={handleTagKeyDown} 
                onChange={handleChangeTagInput} 
                forceValue={true}/>
            <div className="flexer flexer-wrapper my-20">
                {tag_elements}
            </div>
            <InputField name="description"  label='Description' type="text" placeholder={''} required={true} error={errors.description}/>
            <Tooltip content='https://www.example.com' class='full-width' direction='bottom'>
                <InputField name="url"  label='Link' type="url" placeholder={''} required={true} error={errors.url} />
            </Tooltip>
            <div className="right center-mobile">
                <Button text="Cancel" class="mt-30 mx-15 white-btn subheading is-dark" type='button' onClick={props.onClose}/>
                <Button text="Save changes" class="blue-btn mt-30 mx-15 subheading is-white" type="submit"/>
            </div>
        </form>
        }
        </>  
    )
}

export default CreateTool